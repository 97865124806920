import { useCartStore } from '#/common/stores/cart/CartStoreProvider';
import { Button, type ButtonProps } from '#/ui/button';
import { cn } from '#/utils/cn';
import { trpc } from '#/utils/trpc';
import { useToast } from '../ui/use-toast';

interface Props extends ButtonProps {
  actual_price_number: number;
  product_id: string;
  quantity: number;
  minimum: number;
  step: number;
}

export function CartButton({
  product_id,
  actual_price_number,
  minimum,
  quantity,
  step,
  className,
  ...rest
}: Props) {
  const { toast } = useToast();

  const { addProductToCart, itemAdded, removeProductFromCart, setCurrentStep } =
    useCartStore((state) => ({
      addProductToCart: state.addProductToCart,
      itemAdded: state.existsProductOnCart(product_id),
      removeProductFromCart: state.removeProductFromCart,
      setCurrentStep: state.setCurrentStep,
    }));

  const utils = trpc.useUtils();

  const handleToggleItemToOrder = async () => {
    setCurrentStep(1);

    if (itemAdded) {
      toast({
        variant: 'delete',
        title: 'Produto removido do carrinho!',
      });
      removeProductFromCart(product_id);
      return;
    }

    toast({
      variant: 'success',
      title: 'Produto adicionado ao carrinho!',
    });

    addProductToCart({
      actual_price_number,
      product_id,
      quantity,
      minimum,
      step,
    });

    await utils.productRouter.listProductsByIds.invalidate();
  };

  return (
    <Button
      type="button"
      onClick={handleToggleItemToOrder}
      size="icon"
      aria-label="Adicionar ao carrinho"
      className={cn(
        'flex h-7 w-full text-[10px] sm:h-8 sm:text-xs',
        itemAdded &&
          'bg-foreground hover:bg-foreground/90 dark:bg-black dark:hover:bg-neutral-950',
        className,
      )}
      {...rest}
    >
      {itemAdded ? 'Remover do carrinho' : 'Adicionar ao carrinho'}
    </Button>
  );
}
