import { useCustomer } from '#/common/hooks/useCustomer';
import { Button, type ButtonProps } from '#/ui/button';
import { trpc } from '#/utils/trpc';
import { LoginComponent } from '../Login';
import { Dialog, DialogContent, DialogTrigger } from '../ui/dialog';
import { useToast } from '../ui/use-toast';

interface Props extends ButtonProps {
  product_id: string;
}

export function FavoriteButton({ children, product_id, ...rest }: Props) {
  const { toast } = useToast();

  const { isAuthenticated } = useCustomer((state) => state);

  const utils = trpc.useUtils();

  const { data: favoritesProductIds = [] } =
    trpc.customerRouter.listFavoriteProducts.useQuery();

  const isItemFaved = favoritesProductIds.includes(product_id);

  const { mutate } = trpc.customerRouter.updateFavoriteProducts.useMutation({
    onSuccess: async () => {
      await Promise.all([
        utils.customerRouter.listFavoriteProducts.invalidate(),
        utils.productRouter.listProductsByIds.invalidate(),
      ]);
    },
  });

  const handleToggleItemToFavorites = () => {
    if (!isAuthenticated) {
      toast({
        variant: 'default',
        title: 'É necessário estar logado para salvar o produto nos favoritos.',
      });

      return;
    }

    if (isItemFaved) {
      mutate({
        favorite_products: favoritesProductIds.filter((id) => id !== product_id),
      });
      toast({ variant: 'delete', title: 'Produto removido dos favoritos!' });
    } else {
      mutate({
        favorite_products: [...favoritesProductIds, product_id],
      });
      toast({ variant: 'success', title: 'Produto adicionado aos favoritos!' });
    }
  };

  if (!isAuthenticated) {
    return (
      <Dialog>
        <DialogTrigger>
          <Button
            type="button"
            onClick={handleToggleItemToFavorites}
            aria-label="Adicionar aos Favoritos"
            size="icon"
            {...rest}
          >
            {children}
          </Button>
        </DialogTrigger>
        <DialogContent className="sm:max-w-[425px]">
          <LoginComponent />
        </DialogContent>
      </Dialog>
    );
  }

  return (
    <Button
      type="button"
      onClick={handleToggleItemToFavorites}
      size="icon"
      aria-label="Adicionar aos Favoritos"
      {...rest}
    >
      {children}
    </Button>
  );
}
