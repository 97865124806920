import { zodResolver } from '@hookform/resolvers/zod';
import { deleteCookie, getCookie, setCookie } from 'cookies-next';
import { useTheme } from 'next-themes';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useCustomer } from '#/common/hooks/useCustomer';
import { type LoginInput, LoginSchema } from '#/common/schemas/Login.schema';
import { trpc } from '#/utils/trpc';
import { useToast } from '../components/ui/use-toast';

export function useLogin() {
  const { toast } = useToast();
  const router = useRouter();
  const [rememberMe, setRememberMe] = useState(false);
  const setIsAuthenticated = useCustomer((state) => state.setIsAuthenticated);
  const { setTheme } = useTheme();

  const form = useForm<LoginInput>({
    resolver: zodResolver(LoginSchema),
    mode: 'onBlur',
  });

  const utils = trpc.useUtils();

  const {
    mutate: loginMutate,
    isPending: isLoginPending,
    isSuccess,
  } = trpc.authRouter.login.useMutation({
    onSuccess: async (data) => {
      setTheme(data.theme);

      form.reset();
      toast({ variant: 'success', title: 'Logado com Sucesso!' });

      setIsAuthenticated(true);

      await Promise.all([
        utils.customerRouter.getCustomer.invalidate(),
        router.push('/'),
      ]);
    },
    onError: () => {
      toast({ variant: 'destructive', title: 'Usuário ou senha inválidos' });
    },
  });

  useEffect(() => {
    const cookies = JSON.parse(getCookie('login') || '{}');
    if (cookies) {
      const { username, password } = cookies;
      form.setValue('username', username);
      form.setValue('password', password);
      setRememberMe(true);
    }
  }, [form]);

  const handleSignIn = (input: LoginInput) => {
    if (rememberMe) {
      setCookie('login', input, {
        path: '/',
        secure: true,
        maxAge: 60 * 60 * 24 * 7, // 7 days
      });
    } else {
      deleteCookie('login');
    }

    loginMutate(input);
  };

  return {
    form,
    onSubmit: form.handleSubmit(handleSignIn),
    isDisabled: isLoginPending || form.formState.isSubmitting || isSuccess,
    isLoading: isLoginPending || form.formState.isSubmitting,
    handleRememberMe: () => setRememberMe((prevState) => !prevState),
    rememberMe,
  };
}
