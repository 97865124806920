import Link from 'next/link';
import { Icon } from '#/common/components/Icon';
import { Button } from '#/common/components/ui/button';
import { Checkbox } from '#/common/components/ui/checkbox';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '#/common/components/ui/form';
import { Input } from '#/common/components/ui/input';
import { Label } from '#/common/components/ui/label';
import { useLogin } from '#/common/hooks/useLogin';

export function LoginComponent() {
  const { onSubmit, isDisabled, isLoading, form, rememberMe, handleRememberMe } =
    useLogin();

  return (
    <>
      <div className="mt-4 mb-9 flex flex-col items-center gap-1 sm:mt-8">
        <h1 className="font-bold text-xl">ACESSE A SUA CONTA</h1>
        <p className="text-gray-500 text-sm dark:text-gray-400">
          Insira suas informações abaixo
        </p>
      </div>
      <Form {...form}>
        <form
          className="flex flex-col gap-2"
          onSubmit={onSubmit}
        >
          <FormField
            control={form.control}
            name="username"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Nome de usuário ou email</FormLabel>
                <FormControl>
                  <div className="relative">
                    <Icon
                      name="mail"
                      className="absolute top-3 left-3"
                    />
                    <Input
                      placeholder="usuario@gmail.com.br"
                      className="pl-10"
                      {...field}
                    />
                  </div>
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="password"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Senha</FormLabel>
                <FormControl>
                  <div className="relative">
                    <Icon
                      name="key-round"
                      className="absolute top-3 left-3"
                    />
                    <Input
                      className="pl-10"
                      type="password"
                      aria-label="Password"
                      placeholder="***********"
                      {...field}
                    />
                  </div>
                </FormControl>
                <div className="h-4">
                  <FormMessage />
                </div>
              </FormItem>
            )}
          />
          <div className="flex flex-col gap-5">
            <div className="flex items-center gap-2.5 self-center">
              <Checkbox
                checked={rememberMe}
                onClick={handleRememberMe}
                aria-label="Lembrar-me"
              />
              <Label className="text-black/80 dark:text-white/80">Lembrar-me</Label>
            </div>
            <Button
              type="submit"
              disabled={isDisabled}
            >
              {isLoading ? (
                <Icon
                  name="loader"
                  className="animate-spin"
                  size={20}
                />
              ) : (
                'Entrar'
              )}
            </Button>
          </div>
        </form>
      </Form>
      <div className="mt-7 flex flex-col gap-6">
        <Link
          href="/recuperar-senha"
          className="self-center pt-1 text-brand-main text-sm underline decoration-brand-main underline-offset-2 dark:text-brand-dark dark:decoration-brand-dark"
          prefetch={false}
        >
          Esqueci minha senha
        </Link>
        <div className="mt-3 mb-10 flex flex-1 flex-col gap-3">
          <p className="cursor-default text-center text-black/60 text-sm dark:text-white/50">
            Não tem uma conta?
          </p>
          <Button
            variant="outline"
            asChild
          >
            <Link
              className="flex w-full justify-center"
              href="/cadastro"
              prefetch={false}
            >
              Cadastre-se
            </Link>
          </Button>
        </div>
      </div>
    </>
  );
}
