import { useLayoutEffect, useState } from 'react';
import { useWindowSize } from 'usehooks-ts';

export function useQuantityPriorityItems() {
  const [quantityPriorityItems, setQuantityPriorityItems] = useState(5);
  const { width } = useWindowSize();

  useLayoutEffect(() => {
    setQuantityPriorityItems(width <= 768 ? 2 : 5);
  }, [width]);

  return { quantityPriorityItems };
}
