import { z } from 'zod';

export const LoginSchema = z.object({
  username: z
    .string()
    .min(1, { message: 'O nome de usuário deve ter pelo menos 1 caractere' })
    .max(255),
  password: z.string().min(1, { message: 'A senha deve ter pelo menos 1 caractere' }),
});

export type LoginInput = z.infer<typeof LoginSchema>;
